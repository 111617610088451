<!-- src/components/SingleAnswerQuestion.vue -->
<template>
  <div class="max-w-2xl mx-auto p-6 bg-secondary dark:bg-darkSecondary rounded-lg shadow-md">
    <h2 class="text-2xl font-semibold mb-4 text-textPrimary dark:text-darkTextPrimary">
      {{ questionData.question }}
    </h2>
    <div class="grid grid-cols-1 gap-4">
      <button
        v-for="(option, index) in questionData.options"
        :key="index"
        :class="[
          'w-full py-3 px-4 rounded-lg transition-colors duration-200',
          selectedOption === option.value
            ? 'bg-highlight dark:bg-darkHighlight text-white'
            : 'bg-primary hover:bg-hover text-textPrimary dark:bg-darkPrimary dark:text-white dark:hover:bg-darkHighlight dark:hover:text-white',
        ]"
        @click="selectOption(option.value)"
      >
        {{ option.text }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface Option {
  text: string;
  value: string;
}

interface QuestionData {
  type: string;
  key: string;
  question: string;
  options: Option[];
}

export default defineComponent({
  name: 'SingleAnswerQuestion',
  props: {
    questionData: {
      type: Object as PropType<QuestionData>,
      required: true,
    },
    userData: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null as string | null,
    };
  },
  mounted() {
    this.selectedOption = this.userData[this.questionData.key] || null;
  },
  watch: {
    userData: {
      immediate: true,
      handler(newVal) {
        this.selectedOption = newVal[this.questionData.key] || null;
      },
    },
  },
  methods: {
    selectOption(value: string) {
      this.selectedOption = value;
      this.$emit('saveAnswer', { key: this.questionData.key, value });
      this.$emit('next');
    },
  },
});
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>
