<!-- ReCaptcha.vue -->
<template>
  <div ref="recaptcha" class="g-recaptcha"></div>
</template>

<script>
export default {
  name: 'ReCaptcha',
  props: ['siteKey'],
  mounted() {
    if (typeof window.grecaptcha !== 'undefined') {
      this.renderRecaptcha();
    } else {
      // Load the reCAPTCHA script
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`;
      script.async = true;
      script.defer = true;
      window.onloadCallback = this.renderRecaptcha;
      document.body.appendChild(script);
    }
  },
  methods: {
    renderRecaptcha() {
      window.grecaptcha.render(this.$refs.recaptcha, {
        'sitekey': this.siteKey,
        'callback': this.onCaptchaVerified,
      });
    },
    onCaptchaVerified(response) {
      this.$emit('captcha-verified', response);
    },
  },
};
</script>
