<template>
  <div
    class="max-w-2xl mx-auto p-6 bg-primary dark:bg-darkPrimary text-textPrimary dark:text-darkTextPrimary rounded-lg text-center">
    <h1 class="text-3xl font-bold mb-4">Jak lepiej spać?</h1>
    <p class="mb-2">
      Znajdź rozwiązanie swoich <strong>problemów ze snem</strong> dzięki
      <strong>personalnym poradom</strong>.
    </p>
    <p>
      Rozwiązanie quizu zajmie Ci tylko <strong>2 - 3 minuty</strong>, a może
      znacząco poprawić Twój <strong>sen</strong> i <strong>samopoczucie</strong>.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SurveyIntro'
});
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>
