<template>
  <div class="font-poppins min-h-screen bg-primary dark:bg-darkPrimary text-textPrimary dark:text-darkTextPrimary">
    <div class="max-w-2xl mx-auto p-6">

      <p class="text-3xl font-bold mb-4">Dziękujemy za wypełnienie ankiety! Co teraz?</p>
      <ul class="mb-6 list-disc pl-6">
        <li>czekaj na potwierdzenie przesłania ankiety na podany adres e-mail</li>
        <li>otwórz tę wiadomość i kliknij w link potwierdzający subskrypcję</li>
        <li>czekasz zwykle jedną dobę (w zależności od naszego obłożenia)</li>
        <li>otrzymasz od nas wskazówki dotyczące lepszego snu</li>
      </ul>

      <div class="relative w-full pb-[56.25%] h-0 mb-6">
        <iframe class="absolute top-0 left-0 w-full h-full"
          src="https://www.youtube.com/embed/NM1giL2N02E?si=M3jOzJsD-tXLN3Ca" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
      </div>

      <p>
        Potwierdzenie subskrypcji jest niezbędne, abyśmy mogli wysłać Ci wskazówki – to tzw. <strong>podwójne
          potwierdzenie zapisu</strong>, które zapisywaniu innych do mailingów.
      </p>
      <p class="mb-6">
        <u>Nie otrzymałeś</u> informacji w ciągu 15 minut? Sprawdź spam, foldery lub zakładki takie jak
        społeczności/promocje, a jeśli nadal nie
        otrzymałeś maila, napisz do nas na <a href="mailto:kontakt@biohac.pl"
          class="text-highlight dark:text-darkHighlight underline">kontakt@biohac.pl</a> lub w
        <a href="https://m.me/biohacbrand" class="text-highlight dark:text-darkHighlight underline" target="_blank"
          rel="noopener noreferrer">messengerze</a>.
      </p>

      <p class="mb-6">
        Jako przedsmak tego co otrzymasz w mailu, pewnie już jutro, mamy dla Ciebie uniwersalną wskazówkę.
        Dzięki temu już dziś możesz zrobić pierwszy krok do <u>lepszego snu z Biohac</u>.
      </p>

      <p class="text-2xl font-semibold mb-4">Wskazówka na dzisiejszy wieczór i nadchodzący poranek</p>

      <p class="mb-6">
        <strong>Rutyna wieczorna:</strong> skuteczna rutyna wieczorna to klucz do regenerującego snu. Na godzinę przed
        snem wyłącz urządzenia emitujące niebieskie światło, takie jak telewizor, telefon czy komputer – niebieskie
        światło może zaburzać produkcję melatoniny, hormonu regulującego sen. Zamiast tego spróbuj wyciszyć się przy
        książce, relaksującej muzyce lub ciepłej kąpieli. Wprowadź nawyk tworzenia spokojnej atmosfery w sypialni: zapal
        delikatne światło, możesz też użyć świecy sojowej z drewnianym knotem, który stworzy przytulny nastrój i
        delikatny
        trzask ognia. Możesz również skorzystać z technik oddechowych, takich jak głębokie, spokojne oddychanie, co
        pozwoli szybciej się zrelaksować. Rutyna wieczorna ma za zadanie przygotować Twoje ciało i umysł do odpoczynku,
        więc regularność i powtarzalność są kluczowe – to sygnał dla organizmu, że czas na sen.
      </p>

      <p class="mb-6">
        <strong>Rutyna poranna:</strong> pierwsze chwile po przebudzeniu mają ogromne znaczenie dla poziomu energii i
        koncentracji w ciągu dnia. Zamiast natychmiast sięgać po telefon, spróbuj zacząć dzień od kilku minut spokojnego
        rozciągania się, głębokiego oddechu lub krótkiej medytacji, co pozwoli Twojemu umysłowi łagodnie się wybudzić.
        Szklanka ciepłej wody z cytryną wspomoże nawodnienie organizmu po nocy i delikatnie pobudzi metabolizm. Jeżeli
        masz czas, wprowadź krótkie ćwiczenia fizyczne – może to być 10-15 minut jogi, bieganie lub szybki spacer.
        Regularna aktywność fizyczna o poranku poprawia krążenie i nastrój, co pozytywnie wpłynie na resztę dnia. Warto
        też poświęcić chwilę na zaplanowanie dnia, zapisując najważniejsze zadania i cele, co pomoże uniknąć
        niepotrzebnego stresu i rozproszenia.
        Dla przykładu, mistrz olimpijski Mateusz Kusznierewicz w jednym z podcastów podkreśla zalety tzw. power walków,
        czyli intensywnych, energicznych
        spacerów. Według niego, taka aktywność jest świetnym sposobem na pobudzenie organizmu, dotlenienie mózgu i
        podniesienie poziomu energii. Power walk może stanowić doskonałe uzupełnienie porannej rutyny, zwłaszcza jeśli
        preferujesz aktywność na świeżym powietrzu. Regularne spacery w szybkim tempie pomagają poprawić wydolność,
        koncentrację i nastrój, przygotowując Cię na wyzwania dnia.
      </p>

      <p class="mb-6">
        Przestrzeganie tych rutyn może wydawać się wyzwaniem, ale już po kilku tygodniach zauważysz, jak wpływają one na
        jakość życia.
      </p>

      <div class="resources">
        <h2 class="text-2xl font-semibold mb-4">W oczekiwaniu na maila ze wskazówki przeczytaj nasze artykuły o śnie:
        </h2>
        <ul class="article-list mb-6">
          <li v-for="(article, index) in articles" :key="index" class="mb-4">
            <a :href="article.url" target="_blank" class="text-highlight dark:text-darkHighlight underline">
              {{ article.title }}
            </a>
          </li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">Polecamy także kilka naszych produktów:</h2>
        <ul class="product-list mb-6">
          <li v-for="(product, index) in products" :key="index" class="mb-4">
            <a :href="product.url" target="_blank" class="text-highlight dark:text-darkHighlight underline">
              {{ product.name }}
            </a>
            <p>{{ product.description }}</p>
          </li>
        </ul>
      </div>

      <p class="mb-4">
        Odwiedź nasz
        <a href="https://biohac.pl/blog" target="_blank"
          class="text-highlight dark:text-darkHighlight underline">blog</a>,
        aby dowiedzieć się więcej na temat zdrowego stylu życia.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage',
  data() {
    return {
      products: [
        {
          name: 'Świeca Sojowa Drewniany Knot Biała',
          url: 'https://biohac.pl/p/swieca-sojowa-drewniany-knot-biala',
          description: 'Naturalna świeca sojowa z drewnianym knotem, idealna do relaksu przed snem.',
        },
        {
          name: 'Okulary Fusion',
          url: 'https://biohac.pl/p/okulary-fusion',
          description: 'Blokują szkodliwe niebieskie światło, poprawiając jakość snu.',
        },
        {
          name: 'Naklejki blokujące światło – LEDim',
          url: 'https://biohac.pl/p/naklejki-blokujace-swiatlo-ledim',
          description: 'Redukują światło z urządzeń elektronicznych w sypialni.',
        },
      ],
      articles: [
        {
          title: 'Jak poprawić sen w 4 prostych krokach?',
          url: 'https://biohac.pl/sposoby-na-poprawe-snu-efekty-sprawdzisz-w-aplikacji',
        },
        {
          title: 'Jak lepiej spać?',
          url: 'https://biohac.pl/jak-lepiej-spac',
        },
        {
          title: 'Jak sen wpływa na Twój mózg?',
          url: 'https://biohac.pl/jak-sen-wplywa-na-mozg',
        },
        {
          title: 'Dlaczego nie mogę spać podczas pełni Księżyca?',
          url: 'https://biohac.pl/dlaczego-nie-moge-spac-podczas-pelni-ksiezyca',
        }
      ],
    };
  },
};
</script>

<style scoped>
/* Stylizacje specyficzne dla tej komponenty */
</style>
