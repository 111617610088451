import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "max-w-2xl mx-auto p-6 bg-secondary dark:bg-darkSecondary text-textPrimary dark:text-darkTextPrimary rounded-lg shadow-md" }
const _hoisted_2 = { class: "text-2xl font-semibold mb-4" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 mt-4" }
const _hoisted_4 = ["onPointerdown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.questionData.question), 1),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mb-2" }, "Zaznacz wszystkie pasujące odpowiedzi i kliknij \"Dalej\"", -1)),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionData.options, (option, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          class: _normalizeClass([
        'w-full py-3 px-4 rounded-lg transition-colors duration-200',
        _ctx.selectedOptions.includes(option.value)
          ? 'bg-highlight dark:bg-darkHighlight text-white'
          : 'bg-primary text-textPrimary hover:bg-hover dark:bg-darkPrimary dark:text-darkTextPrimary dark:hover:bg-hover',
        !_ctx.selectedOptions.includes(option.value) ? 'hover:bg-hover dark:hover:bg-hover' : '',
      ]),
          onPointerdown: ($event: any) => (_ctx.toggleOption(option.value, $event))
        }, _toDisplayString(option.text), 43, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("button", {
      class: "mt-4 w-full py-3 px-4 rounded-lg bg-highlight text-white hover:bg-hover dark:bg-darkHighlight dark:hover:bg-hover transition-colors duration-200",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitAnswers && _ctx.submitAnswers(...args)))
    }, " Dalej ")
  ]))
}