<template>
  <div v-if="showBanner" class="fixed bottom-4 right-4 max-w-xs bg-gray-800 text-white p-4 z-50 rounded-lg shadow-lg">
    <p class="mb-2 text-sm">
      Używamy plików cookie, aby poprawić funkcjonowanie naszej strony. Kontynuując przeglądanie, wyrażasz zgodę na ich użycie.
      <a href="https://biohac.pl/polityka-prywatnosci" class="underline text-highlight" target="_blank">Dowiedz się więcej</a>.
    </p>
    <div class="text-right">
      <button @click="acceptCookies" class="bg-green-600 hover:bg-green-800 text-white py-1 px-3 rounded">
        Akceptuję
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      showBanner: false,
    };
  },
  mounted() {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      this.showBanner = true;
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'true');
      this.showBanner = false;
    },
  },
};
</script>
