export function validateEmail(email: string | null) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email !== null && re.test(email);
}

export async function sendDataToServer(data: {
  firstName: null;
  gender: null;
  age: null;
  sleepProblems: null;
  hoursOfSleep: null;
  wakingProblems: null;
  sleepIssues: never[];
  influencingFactors: never[];
  electronics: null;
  relaxationTechniques: null;
  caffeineIntake: null;
  lighting: null;
  noise: null;
  email: null;
}) {
  const apiKey = process.env.VUE_APP_API_KEY;
  const backendUrl =  process.env.VUE_APP_BACKEND_URL;
  const url = `${backendUrl}/submit-quiz`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    body: JSON.stringify(data),
  });
  
  return response.json();
}
