// src/questions.js
export default [
    {
      type: 'single',
      key: 'gender',
      question: 'Jaka jest Twoja płeć?',
      options: [
        { text: '🙋‍♂️ Mężczyzna', value: 'male' },
        { text: '🙋‍♀️ Kobieta', value: 'female' },
        { text: 'Inna / Nie chcę podawać', value: 'different_or_dont_want_to_answer' },
      ],
    },
    {
      type: 'single',
      key: 'age',
      question: 'Jaki jest Twój wiek?',
      options: [
        { text: '👶 Mniej niż 18', value: 'less_than_18' },
        { text: '🧑‍🎓 18-25', value: '18-25' },        
        { text: '🧑‍💼 26-34', value: '26-34' },      
        { text: '🧑‍🦱 35-45', value: '35-45' },        
        { text: '🧓 46-59', value: '46-59' },        
        { text: '👴 60+', value: 'more_than_60' },       
      ],
    },
    {
      type: 'single',
      key: 'sleepProblems',
      question: 'Jak często masz problemy z zasypianiem?',
      options: [
        { text: '😴 Nigdy', value: 'never' },
        { text: '😌 Rzadko', value: 'rarely' },
        { text: '😔 Często', value: 'often' },
        { text: '😟 Zawsze', value: 'always' },
      ],
    },
    {
      type: 'single',
      key: 'hoursOfSleep',
      question: 'Ile godzin snu zazwyczaj masz każdej nocy?',
      options: [
        { text: '⏰ Mniej niż 5,5 godziny', value: 'less_than_5.5' },
        { text: '🕒 5,5 - 6,5 godziny', value: '5.5-6.5' },
        { text: '🛌 6,5 - 7,5 godzin', value: '6.5-7.5' },
        { text: '😴 7,5 - 9 godzin', value: '7.5-9' },
        { text: '💤 Więcej niż 9 godzin', value: 'more_than_9' },
      ],
    },
    {
      type: 'single',
      key: 'wakingProblems',
      question: 'Czy budzisz się w nocy i masz problemy z ponownym zaśnięciem?',
      options: [
        { text: '😴 Nigdy (0 razy w tygodniu)', value: '0_times_per_week' },
        { text: '😌 Rzadko (kilka razy w miesiącu)', value: 'few_times_per_month' },
        { text: '😔 Często (kilka razy w tygodniu)', value: 'few_times_per_week' },
        { text: '😟 Bardzo często (każdej nocy)', value: 'every_night' },
        { text: '😫 Zawsze (każdej nocy, więcej niż raz)', value: 'every_night_multiple_times' },
      ],
    },
    {
      type: 'multiple',
      key: 'sleepIssues',
      question: 'Jakie są Twoje najczęstsze problemy ze snem?',
      options: [
        { text: '😴 Ciężko mi zasnąć', value: 'trouble_falling_asleep' },
        { text: '🌜 Budzę się w nocy', value: 'frequent_waking' },
        { text: '🌅 Za wcześnie wstaję', value: 'early_waking' },
        { text: '🌄 Za późno wstaję', value: 'late_waking' },
        { text: '😫 Budzę się niewyspany', value: 'unrefreshing_sleep' },
        { text: '😴 Chrapię', value: 'snoring' },
        { text: '😨 Mam koszmary', value: 'nightmares' },
      ],
    },
    {
      type: 'multiple',
      key: 'influencingFactors',
      question: 'Co najczęściej utrudnia Twój sen?',
      options: [
        { text: '😫 Stres', value: 'stress' },
        { text: '😟 Lęk', value: 'anxiety' },
        { text: '💭 Spirala myśli (overthinking)', value: 'overthinking' },
        { text: '📱 Rozpraszacze', value: 'distractions' },
        { text: '🍲 Niewłaściwa dieta', value: 'bad_diet' },
        { text: '⏰ Zmienna pora snu', value: 'irregular_sleep_schedule' },
      ],
    },
    {
      type: 'single',
      key: 'electronics',
      question: 'Czy korzystasz z ekranów (telefon, komputer, telewizor) przed snem?',
      options: [
        { text: '📵 Nie', value: 'no_before_sleep' },
        { text: '📱 Tak, mniej niż 30 minut przed zaśnięciem', value: 'yes_less_than_30min_before_sleep' },
        { text: '📺 Tak, 30 minut do 1 godziny przed zaśnięciem', value: 'yes_30min_to_1h_before_sleep' },
        { text: '💻 Tak, 1 do 3 godzin przed zaśnięciem', value: 'yes_1h_to_3h_before_sleep' },
        { text: '🖥️ Tak, więcej niż 3 godziny przed zaśnięciem', value: 'yes_more_than_3h_before_sleep' },
      ],
    },
    {
      type: 'single',
      key: 'relaxationTechniques',
      question: 'Czy stosujesz jakieś techniki relaksacyjne przed snem?',
      options: [
        { text: '🧘‍♂️ Nigdy', value: 'never' },
        { text: '😌 Rzadko', value: 'rarely' },
        { text: '😌 Często', value: 'often' },
        { text: '🧘‍♂️ Zawsze', value: 'always' },
      ],
    },
    {
      type: 'single',
      key: 'caffeineIntake',
      question: 'Czy regularnie spożywasz napoje zawierające kofeinę po południu?',
      options: [
        { text: '🥤 Nigdy', value: 'never' },
        { text: '🧋 Rzadko', value: 'rarely' },
        { text: '☕ Często', value: 'often' },
        { text: '🍵 Zawsze', value: 'always' },
      ],
    },
    {
      type: 'single',
      key: 'lighting',
      question: 'Jak oświetlona jest Twoja sypialnia podczas snu?',
      options: [
        { text: '🌑 Całkowicie wyciemnione', value: 'completely_dark' },
        { text: '🔴 Z niewielkimi źródłami światła (diody)', value: 'distracting_lights' },
        { text: '🪟 Światło zewnętrzne (z okna lub z innych pomieszczeń)', value: 'light_from_outside' },
        { text: '💡 Śpię przy jasnym oświetleniu', value: 'bright_light' },
      ],
    },
    {
      type: 'single',
      key: 'noise',
      question: 'Jakie są warunki akustyczne w Twojej sypialni podczas snu?',
      options: [
        { text: '🤫 Jest całkowicie cicho', value: 'completely_quiet' },
        { text: '🔉 Występują niewielkie hałasy, które nie przeszkadzają', value: 'some_noise' },
        { text: '🔊 Są umiarkowane hałasy, które czasem przeszkadzają', value: 'moderate_noise' },
        { text: '🚨 Jest głośno, hałasy utrudniają zasypianie lub wybudzają', value: 'loud_noise' },
      ],
    },
  ];
  