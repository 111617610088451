import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shadow-md bg-secondary dark:bg-darkSecondary" }
const _hoisted_2 = { class: "container mx-auto flex items-center justify-between p-4" }
const _hoisted_3 = { class: "items-center justify-center w-1/3" }
const _hoisted_4 = { class: "text-textPrimary dark:text-darkTextPrimary mr-4" }
const _hoisted_5 = {
  href: "https://biohac.pl?utm_source=quiz",
  title: "Sklep Biohac",
  class: "flex justify-center w-1/3"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex items-center justify-end w-1/3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "w-full h-2 bg-secondary dark:bg-darkSecondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "text-xl text-textPrimary dark:text-darkTextPrimary hover:text-highlight dark:hover:text-darkHighlight",
            style: _normalizeStyle({ visibility: _ctx.currentSlide > 0 && _ctx.currentSlide < _ctx.totalSlides ? 'visible' : 'hidden' }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('prevSlide')))
          }, " ←  ", 4),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentSlide + 1) + "/" + _toDisplayString(_ctx.totalSlides + 1), 1)
        ]),
        _createElementVNode("a", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.logoSrc,
            alt: "Logo Biohac",
            class: "h-8"
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleDarkMode'))),
            class: "flex items-center gap-2 px-4 py-2 border rounded-lg text-textPrimary dark:text-darkTextPrimary hover:bg-gray-100 dark:hover:bg-gray-800"
          }, [
            (_ctx.isDarkMode)
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, "🌙 Ciemny motyw"))
              : (_openBlock(), _createElementBlock("small", _hoisted_9, "🌞 Jasny motyw"))
          ])
        ])
      ])
    ]),
    (_ctx.currentSlide >= 0 && _ctx.currentSlide < _ctx.totalSlides)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "h-2 bg-highlight dark:bg-darkHighlight",
            style: _normalizeStyle({ width: ((_ctx.currentSlide + 1) / _ctx.totalSlides) * 100 + '%' })
          }, null, 4)
        ]))
      : _createCommentVNode("", true)
  ]))
}