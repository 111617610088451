<!-- src/components/MultipleAnswerQuestion.vue -->
<template>
  <div
    class="max-w-2xl mx-auto p-6 bg-secondary dark:bg-darkSecondary text-textPrimary dark:text-darkTextPrimary rounded-lg shadow-md">
    <h2 class="text-2xl font-semibold mb-4">{{ questionData.question }}</h2>
    <p class="mb-2">Zaznacz wszystkie pasujące odpowiedzi i kliknij "Dalej"</p>
    <div class="grid grid-cols-1 gap-4 mt-4">

      <button v-for="(option, index) in questionData.options" :key="index" :class="[
        'w-full py-3 px-4 rounded-lg transition-colors duration-200',
        selectedOptions.includes(option.value)
          ? 'bg-highlight dark:bg-darkHighlight text-white'
          : 'bg-primary text-textPrimary hover:bg-hover dark:bg-darkPrimary dark:text-darkTextPrimary dark:hover:bg-hover',
        !selectedOptions.includes(option.value) ? 'hover:bg-hover dark:hover:bg-hover' : '',
      ]" @pointerdown="toggleOption(option.value, $event)">
        {{ option.text }}
      </button>

    </div>
    <button
      class="mt-4 w-full py-3 px-4 rounded-lg bg-highlight text-white hover:bg-hover dark:bg-darkHighlight dark:hover:bg-hover transition-colors duration-200"
      @click="submitAnswers">
      Dalej
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface Option {
  text: string;
  value: string;
}

interface QuestionData {
  type: string;
  key: string;
  question: string;
  options: Option[];
}

export default defineComponent({
  name: 'MultipleAnswerQuestion',
  props: {
    questionData: {
      type: Object as PropType<QuestionData>,
      required: true,
    },
    userData: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data() {
    return {
      selectedOptions: [] as string[],
    };
  },
  mounted() {
    this.selectedOptions = this.userData[this.questionData.key] || [];
  },
  watch: {
    userData: {
      immediate: true,
      handler(newVal) {
        this.selectedOptions = newVal[this.questionData.key] || [];
      },
    },
  },
  methods: {
    toggleOption(value: string, event: Event) {
      event.stopPropagation()

      if (this.selectedOptions.includes(value)) {
        this.selectedOptions = this.selectedOptions.filter((v) => v !== value);
      } else {
        this.selectedOptions.push(value);
      }
    },
    submitAnswers() {
      this.$emit('saveAnswer', {
        key: this.questionData.key,
        value: this.selectedOptions,
      });
      this.$emit('next');
    },
  },
});
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>
