import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "max-w-2xl mx-auto p-6 bg-secondary dark:bg-darkSecondary rounded-lg shadow-md" }
const _hoisted_2 = { class: "text-2xl font-semibold mb-4 text-textPrimary dark:text-darkTextPrimary" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.questionData.question), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionData.options, (option, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          class: _normalizeClass([
          'w-full py-3 px-4 rounded-lg transition-colors duration-200',
          _ctx.selectedOption === option.value
            ? 'bg-highlight dark:bg-darkHighlight text-white'
            : 'bg-primary hover:bg-hover text-textPrimary dark:bg-darkPrimary dark:text-white dark:hover:bg-darkHighlight dark:hover:text-white',
        ]),
          onClick: ($event: any) => (_ctx.selectOption(option.value))
        }, _toDisplayString(option.text), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}