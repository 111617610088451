<template>
    <div class="max-w-2xl mx-auto py-8 px-6">
      <h2 class="text-2xl font-semibold mb-4">Ankieta poprawy snu - od 🧬 Biohac</h2>
  
      <p class="mb-4">
        Rozwiązując quiz, zgadzasz się z naszą
        <a
          href="https://biohac.pl/polityka-prywatnosci"
          target="_blank"
          class="text-highlight underline"
        ><strong>polityką prywatności</strong></a
        >.
      </p>
  
      <h2 class="text-xl font-semibold mb-2">Dlaczego warto dbać o zdrowy sen?</h2>
      <p class="mb-4">
        Zdrowy sen jest fundamentem naszego dobrego samopoczucia, wpływając na naszą energię,
        koncentrację i ogólną jakość życia. Regularny, spokojny sen pozwala naszemu ciału i umysłowi na
        <strong>regenerację</strong>, co jest kluczowe dla utrzymania zdrowia fizycznego i psychicznego.
      </p>
  
      <img
        src="@/assets/ankieta-lepszy-sen.webp"
        alt="Ankieta na lepszy sen – dowiedz się, jak poprawić jakość snu"
        class="w-full rounded-lg mb-4"
      />
  
      <h2 class="text-xl font-semibold mb-2">Jakie są konsekwencje braku snu?</h2>
      <p class="mb-4">
        <strong>Niedobór snu</strong> może prowadzić do poważnych problemów zdrowotnych, takich jak osłabienie układu
        odpornościowego, problemy z koncentracją, a nawet zwiększone ryzyko chorób przewlekłych. Dlatego tak ważne
        jest, aby zidentyfikować i rozwiązać wszelkie problemy ze snem, zanim wpłyną one negatywnie na Twoje życie.
      </p>
  
      <h2 class="text-xl font-semibold mb-2">Czym jest higiena snu?</h2>
      <p class="mb-4">
        <strong>Higiena snu</strong>, czyli odpowiednie nawyki i rutyny związane z przygotowaniem do snu, odgrywa
        kluczową rolę w zapewnieniu głębokiego i regenerującego snu. Zrozumienie swojego <strong>zegara
        biologicznego</strong> oraz <strong>rytmu dobowego</strong> może pomóc w optymalizacji czasu zasypiania
        i budzenia się.
      </p>
  
      <h2 class="text-xl font-semibold mb-2">Jak dieta i aktywność fizyczna wpływają na sen?</h2>
      <p class="mb-4">
        Czy wiesz, że na jakość snu wpływają także <strong>dieta</strong> i <strong>aktywność fizyczna</strong>?
        Nieodpowiednia dieta i brak ruchu mogą zakłócić rytm snu, podobnie jak nadmierne korzystanie z
        <strong>technologii przed snem</strong>. Ważne jest również, aby Twoje środowisko sypialniane było optymalne
        – cisza, odpowiednia temperatura oraz wygodne łóżko mają duże znaczenie.
      </p>
  
      <h2 class="text-xl font-semibold mb-2">Jak radzić sobie z bezsennością?</h2>
      <p class="mb-4">
        Zmagasz się z <strong>bezsennością</strong>, trudnościami w zasypianiu lub częstym budzeniem się w nocy? Nasz
        quiz pomoże Ci zidentyfikować problemy i dostarczy <strong>spersonalizowanych wskazówek</strong>, jak je
        rozwiązać, korzystając z zaawansowanej <strong>sztucznej inteligencji</strong>.
      </p>
  
      <h2 class="text-xl font-semibold mb-2">Dlaczego warto rozwiązać naszą ankietę na poprawę snu?</h2>
      <p class="mb-4">
        Rozwiązując tę ankietę, dowiesz się, jak poprawić jakość snu i zapewnić sobie <strong>lepszy
        wypoczynek</strong>. Nasza <strong>sztuczna inteligencja</strong> przeanalizuje Twoje odpowiedzi i
        zaproponuje porady dostosowane do Twoich indywidualnych potrzeb, abyś mógł spać lepiej każdej nocy.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SurveyOutro',
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here if needed */
  </style>
  