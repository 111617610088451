<!-- App.vue -->
<template>
  <div :class="`font-poppins ${isDarkMode ? 'dark' : ''}`">
    <BiohacNavbar :currentSlide="currentSlide" :totalSlides="totalSlides" :isDarkMode="isDarkMode"
      @toggleDarkMode="toggleDarkMode" @prevSlide="prevSlide" />

    <!-- Pasek błędu -->
    <div v-if="serverError" class="bg-red-600 text-white text-center p-3">
      {{ serverError }}
    </div>

    <div v-if="!isQuizSubmitted"
      class="font-poppins min-h-screen bg-primary dark:bg-darkPrimary text-textPrimary dark:text-darkTextPrimary">

      <!-- Intro Component -->
      <SurveyIntro v-if="currentSlide === 0" />

      <!-- Quiz Questions -->
      <div class="max-w-2xl mx-auto p-6">
        <component v-for="(question, index) in questions" :key="index" :is="getComponent(question)"
          :questionData="question" @next="handleNext" @prev="prevSlide" @saveAnswer="saveAnswer" :userData="userData"
          v-show="currentSlide === index" />


        <!-- Email Submission -->
        <div v-if="currentSlide === questions.length">
          <h2 class="text-2xl font-semibold mb-4">Podaj dane kontaktowe abyśmy mogli wysłać do Ciebie porady:</h2>

          <!-- Pole Imię (opcjonalne) -->
          <label for="name" class="block mb-2 font-semibold">Imię:</label>
          <input id="name" type="text" v-model="userData.firstName" placeholder="Imię" autocomplete="given-name"
            class="w-full border border-gray-600 bg-primary dark:bg-darkPrimary text-textPrimary dark:text-darkTextPrimary p-2 rounded mb-4" />

          <!-- Pole Email (wymagane) -->
          <label for="email" class="block mb-2 font-semibold">
            Email: <span class="text-red-500">*</span>
          </label>
          <input id="email" type="email" v-model="userData.email" placeholder="Email" required autocomplete="email"
            class="w-full border border-gray-600 bg-primary dark:bg-darkPrimary text-textPrimary dark:text-darkTextPrimary p-2 rounded mb-4" />

          <!-- Zgody -->
          <label class="block mb-2">
            <input type="checkbox" v-model="allConsents" @change="acceptAllConsents" />
            <span class="font-semibold text-highlight">&nbsp;Akceptuję wszystkie wymagane zgody</span>
          </label>
          <label class="block mb-2">
            <input type="checkbox" v-model="privacyPolicyChecked" @change="checkAllConsents" />
            <span class="text-red-500">&nbsp;*</span> Akceptuję
            <a href="https://biohac.pl/polityka-prywatnosci" target="_blank" class="text-highlight underline">
              politykę prywatności
            </a>
          </label>
          <label class="block mb-4">
            <input type="checkbox" v-model="newsletterChecked" @change="checkAllConsents" />
            <span class="text-red-500">&nbsp;*</span> Zapisuję się do newslettera
          </label>

          <!-- ReCaptcha Component -->
          <ReCaptcha :siteKey="recaptchaSiteKey" @captcha-verified="onCaptchaVerified" class="mb-4" />

          <!-- Przycisk Wyślij -->
          <button @click="submitQuiz" :class="{
            'bg-green-700': !isSubmitting,
            'hover:bg-green-900': !isSubmitting,
            'bg-gray-500': isSubmitting,
            'text-white py-2 px-4 rounded w-full': true
          }" :disabled="isSubmitting || serverError">
            {{ isSubmitting ? 'Wysyłanie...' : 'Wyślij' }}
          </button>

          <!-- Wiadomość informacyjna -->
          <p class="text-sm text-light my-2">
            Pamiętaj, że nasze porady nie są poradami medycznymi. Skonsultuj się z lekarzem w przypadku problemów
            zdrowotnych.
          </p>
          <p class="text-sm text-light mb-4">
            Poradę przygotujemy najszybciej jak możemy (zwykle następnego dnia) i wyślemy ją mailem po potwierdzeniu
            zapisu do newslettera.
          </p>

        </div>

      </div>

      <!-- Outro -->
      <SurveyOutro v-if="currentSlide === 0" />
    </div>

    <!-- Thank You Page -->
    <ThankYouPage v-else />

    <CookieConsent />

  </div>
</template>

<script>
import BiohacNavbar from './components/BiohacNavbar.vue';
import SingleAnswerQuestion from './components/SingleAnswerQuestion.vue';
import MultipleAnswerQuestion from './components/MultipleAnswerQuestion.vue';
import ThankYouPage from './components/ThankYouPage.vue';
import CookieConsent from './components/CookieConsent.vue';
import ReCaptcha from './components/ReCaptcha.vue';
import questions from './questions.ts';
import { validateEmail, sendDataToServer } from './utils.ts';
import SurveyOutro from './components/SurveyOutro.vue';
import SurveyIntro from './components/SurveyIntro.vue';
import CryptoJS from 'crypto-js';

function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export default {
  name: 'App',
  components: {
    BiohacNavbar,
    SingleAnswerQuestion,
    MultipleAnswerQuestion,
    SurveyIntro,
    SurveyOutro,
    CookieConsent,
    ReCaptcha,
    ThankYouPage,
  },
  data() {
    return {
      currentSlide: 0,
      isDarkMode: true,
      userData: {
        firstName: null,
        gender: null,
        age: null,
        sleepProblems: null,
        hoursOfSleep: null,
        wakingProblems: null,
        sleepIssues: [],
        influencingFactors: [],
        electronics: null,
        relaxationTechniques: null,
        caffeineIntake: null,
        lighting: null,
        noise: null,
        email: null,
      },
      questions,
      allConsents: false,
      privacyPolicyChecked: false,
      newsletterChecked: false,
      isSubmitting: false,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      serverError: null,
      isQuizSubmitted: false,
      captchaResponse: null,
    };
  },
  computed: {
    totalSlides() {
      return this.questions.length;
    },
    hasAnsweredQuestions() {
      return Object.values(this.userData).some(value => {
        // Check if the value is not null, undefined, or an empty array
        return value !== null && value !== undefined && (Array.isArray(value) ? value.length > 0 : true);
      });
    },
  },
  mounted() {
    this.checkServerHealth();

    // Set dark mode based on URL parameter
    const darkModeParam = getQueryParam('darkmode');
    if (darkModeParam !== null) {
      this.isDarkMode = darkModeParam === 'true' || darkModeParam === '1';
    } else {
      // Check user's system preference for dark mode
      const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
      this.isDarkMode = prefersDarkScheme.matches;

      // Listen for changes in system preference
      prefersDarkScheme.addEventListener('change', (event) => {
        this.isDarkMode = event.matches;
        this.applyDarkModeClass();
      });
    }

    // Apply the dark mode class
    this.applyDarkModeClass();
    // Track page view
    this.trackPageView();

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    applyDarkModeClass() {
      if (this.isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    },
    onCaptchaVerified(response) {
      this.captchaResponse = response;
    },
    handleBeforeUnload(event) {
      // Check if at least one question has been answered and the quiz is not submitted yet
      if (this.hasAnsweredQuestions && !this.isQuizSubmitted) {
        // Most browsers ignore the custom message and show a default warning
        event.preventDefault();
        event.returnValue = '';
      }
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      if (this.isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    },
    nextSlide() {
      if (this.currentSlide < this.questions.length + 1) {
        this.currentSlide++;
      }
    },
    prevSlide() {
      if (this.currentSlide > -1) {
        this.currentSlide--;
      }
    },
    getComponent(question) {
      if (question.type === 'single') {
        return 'SingleAnswerQuestion';
      } else if (question.type === 'multiple') {
        return 'MultipleAnswerQuestion';
      }
      return null;
    },
    saveAnswer({ key, value }) {
      this.userData[key] = value;
    },
    handleNext() {
      this.nextSlide();
    },
    acceptAllConsents() {
      this.privacyPolicyChecked = this.allConsents;
      this.newsletterChecked = this.allConsents;
    },
    checkAllConsents() {
      this.allConsents = this.privacyPolicyChecked && this.newsletterChecked;
    },
    async checkServerHealth() {
      try {
        const backendUrl = process.env.VUE_APP_BACKEND_URL;
        const url = `${backendUrl}/health`;
        const response = await fetch(url);
        if (response.status !== 200) {
          throw new Error('Serwer jest niedostępny');
        }
      } catch (error) {
        this.serverError = 'Obecnie występują problemy z serwerem. Wysyłanie formularza jest niedostępne.';
      }
    },
    async submitQuiz() {
      if (!validateEmail(this.userData.email)) {
        alert('Proszę podać poprawny adres email.');
        return;
      }
      if (!this.privacyPolicyChecked || !this.newsletterChecked) {
        alert('Proszę zaakceptować wymagane pola.');
        return;
      }
      if (!this.captchaResponse) {
        alert('Proszę zaznaczyć reCAPTCHA.');
        return;
      }

      this.isSubmitting = true;

      try {
        const hashedEmail = this.hashEmail();
        window.fbq('track', 'Lead', { email: hashedEmail });

        await Promise.all([
          sendDataToServer(this.userData),
          this.trackQuizCompletion(hashedEmail)
        ]);

        this.isQuizSubmitted = true;
      } catch (error) {
        console.error('Error sending data to the server:', error);
        alert('Wystąpił błąd podczas wysyłania danych. Spróbuj ponownie później.');
      } finally {
        this.isSubmitting = false;
      }
    },
    isCaptchaValid() {
      return window.isCaptchaValid;
    },
    hashEmail() {
      const email = this.userData.email.trim().toLowerCase();
      return CryptoJS.SHA256(email).toString();
    },
    async trackQuizCompletion(hashedEmail) {
      try {
        const apiKey = process.env.VUE_APP_API_KEY;
        const backendUrl = process.env.VUE_APP_BACKEND_URL;
        await fetch(`${backendUrl}/track-quiz-completion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
          body: JSON.stringify({ email: hashedEmail }),
        });
      } catch (error) {
        console.error('Error tracking quiz completion:', error);
      }
    },
    async trackPageView() {
      try {
        window.fbq('track', 'PageView');

        const apiKey = process.env.VUE_APP_API_KEY;
        const backendUrl = process.env.VUE_APP_BACKEND_URL;
        await fetch(`${backendUrl}/track-page-view`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
        });
      } catch (error) {
        console.error('Error tracking page view:', error);
      }
    },
  },
};
</script>
