<template>
  <div>
    <nav class="shadow-md bg-secondary dark:bg-darkSecondary">
      <div class="container mx-auto flex items-center justify-between p-4">
        <!-- Left side -->
        <div class="items-center justify-center w-1/3">
          <button
            class="text-xl text-textPrimary dark:text-darkTextPrimary hover:text-highlight dark:hover:text-darkHighlight"
            :style="{ visibility: currentSlide > 0 && currentSlide < totalSlides ? 'visible' : 'hidden' }"
            @click="$emit('prevSlide')">
            ←&nbsp;
          </button>
          <span class="text-textPrimary dark:text-darkTextPrimary mr-4">
            {{ currentSlide + 1 }}/{{ totalSlides + 1 }}
          </span>
        </div>

        <!-- Centered logo -->
        <a href="https://biohac.pl?utm_source=quiz" title="Sklep Biohac" class="flex justify-center w-1/3">
          <img :src="logoSrc" alt="Logo Biohac" class="h-8" />
        </a>

        <!-- Right side -->
        <div class="flex items-center justify-end w-1/3">
          <button @click="$emit('toggleDarkMode')"
            class="flex items-center gap-2 px-4 py-2 border rounded-lg text-textPrimary dark:text-darkTextPrimary hover:bg-gray-100 dark:hover:bg-gray-800">
            <small v-if="isDarkMode">🌙 Ciemny motyw</small>
            <small v-else>🌞 Jasny motyw</small>
          </button>
        </div>
      </div>
    </nav>
    <div v-if="currentSlide >= 0 && currentSlide < totalSlides" class="w-full h-2 bg-secondary dark:bg-darkSecondary">
      <div class="h-2 bg-highlight dark:bg-darkHighlight"
        :style="{ width: ((currentSlide + 1) / totalSlides) * 100 + '%' }"></div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import logoWhite from '../assets/logo-white.svg';
import logoBlack from '@/assets/logo-black.svg';

export default defineComponent({
  name: 'BiohacNavbar',
  props: {
    currentSlide: {
      type: Number,
      required: true,
    },
    totalSlides: {
      type: Number,
      required: true,
    },
    isDarkMode: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleDarkMode', 'prevSlide'],
  computed: {
    logoSrc(): string {
      return this.isDarkMode ? logoWhite : logoBlack;
    },
  },
});
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>
